.bg-primary {
	background-color: $primary !important;
}

.text-primary {
	color: $green-100 !important;
}

a {
	color: $black-100;
	&:hover,
	&:focus,
	&:active {
		color: darken($primary, 15%);
	}
}

pre {
	color: $green-100;
}

code.highlighter-rouge {
	color: $green-100;
}
