section.main-section {
	padding-top: 5rem !important;
	padding-bottom: 5rem !important;
	max-width: 75rem;
		.main-item {
			.main-content {}
			.main-date {
				min-width: none;
			}
			.main-email {
				margin-top: 0;
				color: $black-100;				
				font-size: 1.65rem;
				font-weight: bold;
			}
		}
}

@media (min-width: 768px) {
	section.main-section {
		min-height: 100vh;
			.main-item {
				.main-date {
					min-width: 18rem;
				}
		}
	}
}

@media (min-width: 992px) {
	section.main-section {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
}
